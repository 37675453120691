import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSigned } from '../../hooks/signed';

const DefaultPage = () => {
  const navigate = useNavigate();
  const documentId = localStorage.getItem('documentId');
  const signed = useSigned(documentId);

  useEffect(() => {
    if (signed && documentId) {
      navigate('success');
    } else {
      navigate('failed');
    }
  }, [documentId, signed, navigate]);

  return <></>;
};

export default DefaultPage;
