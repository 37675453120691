import React, { useCallback, useEffect } from 'react';
import styles from './success.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import Socials from '../../components/socials';
import { getPdfReceipt } from '../../api/apiService';
import fileDownload from 'js-file-download';
import { useNavigate } from 'react-router-dom';
import { useSigned } from '../../hooks/signed';

const SuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const documentId = localStorage.getItem('documentId');
  const signed = useSigned(documentId);

  const downloadReceipt = useCallback(async () => {
    if (documentId) {
      const receipt: any = await getPdfReceipt(documentId);
      fileDownload(receipt, 'receipt.pdf');
    }
  }, [documentId]);

  useEffect(() => {
    if (!signed) {
      navigate('/failed');
    }
  }, [documentId, navigate, signed]);

  return (
    <div className={styles.success}>
      <div className={styles.top}>
        <div>
          <strong className="title">{t('success')}</strong>
        </div>
      </div>

      <div className={styles.main}>
        <strong>{t('you_successfully_signed_receipt')}</strong>
        <p>{t('press_button_to_download_receipt')}</p>
        <div className={styles.warning}>{t('download_link_available_72_hours')}</div>
        <div className={styles.buttons}>
          <Button disabled={!documentId} className="full-width" onClick={downloadReceipt}>
            {t('download_receipt')}
          </Button>
          <Button
            className="full-width secondary"
            onClick={() => (window.location.href = 'https://stbunion.app.link/B0m5mKE8idb')}
          >
            {t('go_to_stb_app')}
          </Button>
        </div>

        <div className={styles.socials}>{t('visit_us_online')}</div>
        <Socials></Socials>
      </div>
    </div>
  );
};

export default SuccessPage;
