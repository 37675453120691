import React, { FC, useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import styles from './transaction.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../components/spinner';
import { getTransactionInfo, sendEmail } from '../../api/apiService';
import emailValidator from '../../helpers/email-validator';
import { useSigned } from '../../hooks/signed';

const TransactionPage: FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [transaction, setTransaction]: any[] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [emailValidity, setEmailValidity] = useState(true);
  const [email, setEmail] = useState('');
  const signed = useSigned(documentId);

  const handleChangeEmail = (value: string) => {
    const valid = emailValidator(value) || value === '';
    setEmailValidity(valid);

    if (valid) {
      setEmail(value);
      localStorage.setItem('email', value);
    } else {
      setEmail('');
      localStorage.removeItem('email');
    }
  };

  const getTransaction = useCallback(async () => {
    if (!documentId) {
      navigate('/failed');
      return;
    }

    if (transaction) {
      return;
    }
    setSpinner(true);

    try {
      const transaction = await getTransactionInfo(documentId);
      if (!transaction) {
        navigate('/failed');
      }
      localStorage.setItem('documentId', documentId);
      setTransaction(transaction);
      setSpinner(false);
    } catch ({ message }) {
      navigate('/failed');
      setSpinner(false);
    }
  }, [documentId, transaction, navigate]);

  const handleClickNext = useCallback(async () => {
    if (!email || !documentId) {
      navigate('/receipt');
    } else {
      const payload = {
        email: email,
        link: documentId,
      };
      setSpinner(true);
      await sendEmail(payload).catch(() => {});
      setSpinner(false);
      navigate('/receipt');
    }
  }, [email, documentId, navigate]);

  useEffect(() => {
    const email = localStorage.getItem('email') || '';
    if (email) {
      setEmail(email);
    }

    const oldDocumentId = localStorage.getItem('documentId');

    if (oldDocumentId === documentId && signed) {
      navigate('/success');
      return;
    }

    getTransaction();
  }, [documentId, transaction, getTransaction, setEmail, navigate, signed]);

  return (
    <>
      {spinner && <Spinner fullPage={true}></Spinner>}
      <Header></Header>
      <div className="container">
        {transaction && (
          <div className={styles.transaction}>
            <label>{t('transfer_number')}</label>
            <div className={styles.number}>{transaction.reservationCode}</div>

            <div className={styles.info}>
              <div className={styles.block}>
                <label>{t('operator')}</label>
                <span className="value">{transaction.operator}</span>
              </div>
              <div className={styles.block}>
                <label>{t('receiver')}</label>
                <span className="value">{transaction.receiver}</span>
              </div>
              <div className={styles.block}>
                <label>{t('country')}</label>
                <span className="value">{transaction.country}</span>
              </div>
              <div className={styles.block}>
                <label>{t('amount')}</label>
                <span className="value">
                  {transaction.amount} {transaction.currency}
                </span>
              </div>
              <div className={styles.block}>
                <label>{t('fee')}</label>
                <span className="value">
                  {transaction.fee} {transaction.feeCurrency}
                </span>
              </div>
            </div>

            <div className={styles.emailForm}>
              <label>{t('send_copy_to_my_email')}</label>
              <div>
                <span className="material-symbols-outlined">mail</span>
                <input
                  type="email"
                  placeholder="Email"
                  defaultValue={email}
                  onChange={({ target: { value } }) => {
                    handleChangeEmail(value);
                  }}
                />
              </div>
            </div>

            <div className={styles.buttons}>
              <Button disabled={!emailValidity} onClick={handleClickNext}>
                {t('next_step')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionPage;
