import axios from 'axios';

export const getTransactionInfo = (docId: string) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/unAuth/documentSign/${docId}`,
  }).then(({ data }) => data);
};

export const getPdfReceipt = (docId: string) => {
  return axios({
    method: 'get',
    responseType: 'arraybuffer',
    url: `${process.env.REACT_APP_API_URL}/api/transaction-pdf/api/v1/unAuth/sign/${docId}`,
  }).then(({ data }) => new File([data], 'receipt'));
};

export const sendEmail = (payload: { link: string; email: string }) => {
  return axios({
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/unAuth/documentSign`,
    data: payload,
  }).then(({ data }) => new File([data], 'receipt'));
};

export const uploadImage = (data: { file: string; id: string; type: string }) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/api/file/api/v1/image/private`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
