import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import './App.scss';
import DefaultPage from './pages/default';
import FailedPage from './pages/failed';
import ReceiptPage from './pages/receipt';

import SignPage from './pages/sign';
import SuccessPage from './pages/success';
import TransactionPage from './pages/transaction';

function App() {
  const INTERCOM_APP_ID = 'oe159t1v';
  const { i18n } = useTranslation();
  const [dir, setDir] = useState('');

  useEffect(() => {
    const lang = i18n.language.split('-')[0];
    setDir(lang === 'il' ? 'rtl' : '');
  }, [i18n.language]);

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <div dir={dir}>
        <BrowserRouter>
          <Routes>
            <Route path="document/:documentId" element={<TransactionPage />} />
            <Route path="s/:documentId" element={<TransactionPage />} />
            <Route path="receipt" element={<ReceiptPage />} />
            <Route path="sign" element={<SignPage />} />
            <Route path="success" element={<SuccessPage />} />
            <Route path="failed" element={<FailedPage />} />
            <Route path="*" element={<DefaultPage />} />
          </Routes>
        </BrowserRouter>
        <Outlet />
      </div>
    </IntercomProvider>
  );
}

export default App;
